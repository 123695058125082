<template>
	<div style="background: #f9f9fb;height: 100vh;">
		<div v-if="type==false">
			<div style="padding: 10px 0 60px 0;">

				<div style="background: #fff;padding: 20px;border-bottom: 1px solid #f3f3f3;" v-for="(item,index) in list" :key="index">
					<div style="display: flex;">
						<div>
							<a-radio-group v-model="radioId">
							    <a-radio :style="radioStyle" :value="item.id" @click.native.prevent="onListChange"></a-radio>
							</a-radio-group>
						</div>
						<div style="width: 80%;">
							<div style="font-weight: bold;">{{item.province}}{{item.city}}{{item.area}}{{item.streetAdd}}</div>
							<div style="display: flex;color: #8a8a8a;padding-top: 5px;align-items: center;">
								<div>{{item.contactuser}}</div>
								<div style="padding-left: 10px;">{{geTel(item.contacttel)}}</div>
								<div
									style="padding: 2px 4px;border-radius: 5px;background: #d81e06;margin-left: 10px;color: #fff;" v-if="item.isdefault==1">
									默认</div>
							</div>
						</div>
						<div style="width: 10%;display: flex;align-items: center;justify-content: center;" @click="updataClick(item)">
							<img style="width: 20px;height: 20px;" src="../../assets/img/icon/modify.png"></img>
						</div>
					</div>
				</div>

			</div>
			
			<div style="display: flex;position: fixed;bottom: 0;width: 100%;">
				<div style="padding: 20px 0 20px 20px;background: #fff;width: 50%;color: #fff;">
					<div style="padding: 10px;text-align: center;width: 100%;background: #d9d9d9;border-top-left-radius: 30px;border-bottom-left-radius: 30px;"
						@click="deleteValue">删除地址</div>
				</div>
				<div style="padding: 20px 20px 20px 0;background: #fff;width: 50%;color: #fff;">
					<div style="padding: 10px;text-align: center;width: 100%;background: #00bd24;border-top-right-radius: 30px;border-bottom-right-radius: 30px;"
						@click="addClick">新增收货地址</div>
				</div>
			</div>
		</div>

		<div v-if="type==true">
			<div style="padding: 20px 0;">
				<div style="background: #fff;padding:20px;width: 100%;border-bottom: 1px solid #f3f3f3;" @click="distpickerPopup">
					<div style="display: flex;">
						<div style="font-weight: bold;width: 30%;display: flex;"><div style="color: red;">*</div>选择地址</div>
						<div style="width: 70%;" v-if="this.form.area==undefined">请点击选择省市区</div>
						<div style="width: 70%;" v-else>{{this.form.province}} - {{this.form.city}} - {{this.form.area}}</div>
					</div>
				</div>
				
				<div style="background: #fff;padding:20px;width: 100%;border-bottom: 1px solid #f3f3f3;">
					<div style="display: flex;">
						<div style="font-weight: bold;width: 30%;display: flex;"><div style="color: red;">*</div>街道地址</div>
						<div style="width: 70%;">
							<el-input
							  type="textarea"
							  :autosize="{ minRows: 2, maxRows: 4}"
							  placeholder="请输入内容" v-model="form.streetAdd"></el-input>
						</div>
					</div>
				</div>
				
				<div style="background: #fff;padding:20px;width: 100%;border-bottom: 1px solid #f3f3f3;">
					<div style="display: flex;">
						<div style="font-weight: bold;width: 30%;display: flex;"><div style="color: red;">*</div>联系人</div>
						<div style="width: 70%;">
							<a-input placeholder="请输入联系人" v-model.trim="form.contacts" />
						</div>
					</div>
				</div>
				
				<div style="background: #fff;padding:20px;width: 100%;border-bottom: 1px solid #f3f3f3;">
					<div style="display: flex;">
						<div style="font-weight: bold;width: 30%;display: flex;"><div style="color: red;">*</div>联系电话</div>
						<div style="width: 70%;">
							<a-input placeholder="请输入电话" v-model.trim="form.phone" />
						</div>
					</div>
				</div>
				
				<div style="background: #fff;padding:20px;width: 100%;border-bottom: 1px solid #f3f3f3;">
					<div style="display: flex;">
						<div style="font-weight: bold;width: 30%;">是否默认</div>
						<div style="width: 70%;">
							<a-radio-group v-model="form.type">
							    <a-radio :style="radioStyle" :value="1" @click.native.prevent="onChange">
							      设置默认地址
							    </a-radio>
							</a-radio-group>
						</div>
					</div>
				</div>
			</div>
			
			<div style="display: flex;position: fixed;bottom: 0;width: 100%;">
				<div style="padding: 20px 0 20px 20px;background: #fff;width: 50%;color: #fff;">
					<div style="padding: 10px;text-align: center;width: 100%;background: #d9d9d9;border-top-left-radius: 30px;border-bottom-left-radius: 30px;"
						@click="addOff">关闭</div>
				</div>
				<div style="padding: 20px 20px 20px 0;background: #fff;width: 50%;color: #fff;" v-if="addType==0">
					<div style="padding: 10px;text-align: center;width: 100%;background: #00bd24;border-top-right-radius: 30px;border-bottom-right-radius: 30px;"
						@click="newAddress">新增地址</div>
				</div>
				<div style="padding: 20px 20px 20px 0;background: #fff;width: 50%;color: #fff;" v-if="addType==1">
					<div style="padding: 10px;text-align: center;width: 100%;background: #d81e06;border-top-right-radius: 30px;border-bottom-right-radius: 30px;"
						@click="updataAddress">修改地址信息</div>
				</div>
			</div>
		</div>
		
		<el-drawer title="省市区选择" :visible.sync="drawer" :direction="direction" :before-close="handleClose" size="60%">
			<v-distpicker :province="form.province" :city="form.city" :area="form.area" type="mobile"
				@province="onChangeProvince" @city="onChangeCity" @area="onChangeArea"></v-distpicker>
		</el-drawer>
	</div>
</template>

<script>
	import {
		getUser,
		updateUser,
		updatepword,
		getMyAddList,
		defaultAdd,
		updateAdd,
		saveAddress
	} from '@/api/userInfo/index'
	export default {
		data() {
			return {
				type: false,//判断是新增还是列表状态
				addType: 0,//0是新增1是修改
				form: {
					id: undefined, //id
					itemid: undefined,//数据id
					userid: undefined, //uid
					province: undefined, //省
					city: undefined, //市
					area: undefined, //取
					streetAdd: undefined, //街道地址
					contacts: undefined, //联系人
					phone: undefined, //联系电话
					company: undefined, //公司名
					text: undefined, //合成字符串
					type: undefined, //是否默认
				},
				radioId:undefined,//选择列表
				list:null,//列表

				drawer: false,
				direction: 'btt',
				
				radioStyle: {
					display: 'block',
					height: '30px',
					lineHeight: '30px',
				},
				
				// 地址接口传参
				queryParam: {
					pageNum: 1,
					pageSize: 10,
					uid: undefined,
					isdelete:0
				},
			}
		},

		created() {
			this.userInfo = JSON.parse(sessionStorage.getItem("getUserInfo"))
			this.getUser()
		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {
			geTel: function(tel) {
				var reg = /^(\d{3})\d{4}(\d{4})$/;
				return tel.replace(reg, "$1****$2");
			},

			addClick: function() {
				this.addType = 0;
				this.form.province = undefined; //省
				this.form.city = undefined; //市
				this.form.area = undefined; //取
				this.form.streetAdd = undefined; //街道地址
				this.form.contacts = undefined; //联系人
				this.form.phone = undefined; //联系电话
				this.form.company = undefined; //公司名
				this.form.text = undefined; //合成字符串
				this.form.type = undefined; //是否默认
				this.type = true;
			},
			
			//--------------获取用户信息数据----------------------
			getUser: function() {
				let data = {
					id: this.userInfo.uid
				}
				getUser(data).then(res => {
					this.form.id = res.data.id
					this.queryParam.uid=res.data.id
					sessionStorage.setItem("userInfo", JSON.stringify(this.userInfo))
					
					this.getMyAddList()
				})
			},
			
			//------------------查询用户收货地址接口---------------------
			getMyAddList:function(){
				getMyAddList(this.queryParam).then(res=>{
					this.list=res.data.content
				})
			},

			//------------------选择地区--------------------------------
			onChangeProvince(e) {
				// console.log(e)
				this.form.province = e.value
			},
			onChangeCity(e) {
				// console.log(e)
				this.form.city = e.value
			},
			onChangeArea(e) {
				// console.log(e)
				this.form.area = e.value
				this.drawer=false
			},
			
			distpickerPopup(){
				this.drawer=true
			},

			handleClose(done) {
				this.drawer=false
			},
			
			//--------------关闭新增页面----------------------
			addOff:function(){
				this.form.province = undefined; //省
				this.form.city = undefined; //市
				this.form.area = undefined; //取
				this.form.streetAdd = undefined; //街道地址
				this.form.contacts = undefined; //联系人
				this.form.phone = undefined; //联系电话
				this.form.company = undefined; //公司名
				this.form.text = undefined; //合成字符串
				this.form.type = undefined; //是否默认
				this.type=false
			},
			
			//----------------------是否默认----------------------
			onChange:function(e){
				if(this.form.type==1){
					this.form.type=undefined
				}else{
					this.form.type=1
				}
				// console.log(this.form.type)
			},
			
			//-------------------选择list列表--------------------
			onListChange:function(e){
				if(this.radioId == e.target._value){
					this.radioId=undefined
				}else{
					this.radioId=e.target._value
				}
				// console.log(this.radioId)
			},
			
			//---------------新增地址--------------------------
			newAddress:function(){
				if(this.form.province==undefined){
					this.$message.error('请选择地址')
				}else if(this.form.province==''){
					this.$message.error('请选择地址')
				}else if(this.form.city==undefined){
					this.$message.error('请选择地址')
				}else if(this.form.city==''){
					this.$message.error('请选择地址')
				}else if(this.form.area==undefined){
					this.$message.error('请选择地址')
				}else if(this.form.area==''){
					this.$message.error('请选择地址')
				}else if(this.form.streetAdd==undefined){
					this.$message.error("请输入街道地址")
				}else if(this.form.streetAdd==''){
					this.$message.error("请输入街道地址")
				}else if(this.form.contacts==undefined){
					this.$message.error("请输入联系人")
				}else if(this.form.contacts==''){
					this.$message.error("请输入联系人")
				}else if(this.form.phone==undefined){
					this.$message.error("请输入电话号码")
				}else if(this.form.phone==''){
					this.$message.error("请输入电话号码")
				}else if(!(/^1[3456789]\d{9}$/.test(this.form.phone))){
					this.$message.error("请输入正确的电话号码")
				}else{
					let data={
						userid: this.form.id,
						province:this.form.province,
						city:this.form.city,
						region:this.form.area,
						address:this.form.streetAdd,
						contactuser:this.form.contacts,
						contacttel:this.form.phone,
						isdefault:this.form.type
					}
					saveAddress(data).then(res=>{
						if(res.code=="200"){
							this.type=false
							this.$message.success(
								'新增成功',
							)
							this.getUser()
						}else{
							this.$message.error(
								'新增失败',
							)
						}
					})
				}
			},
			
			//----------------------修改地址信息------------------------
			updataAddress:function(){
				if(this.form.province==undefined){
					this.$message.error('请选择地址')
				}else if(this.form.province==''){
					this.$message.error('请选择地址')
				}else if(this.form.city==undefined){
					this.$message.error('请选择地址')
				}else if(this.form.city==''){
					this.$message.error('请选择地址')
				}else if(this.form.area==undefined){
					this.$message.error('请选择地址')
				}else if(this.form.area==''){
					this.$message.error('请选择地址')
				}else if(this.form.streetAdd==undefined){
					this.$message.error("请输入街道地址")
				}else if(this.form.streetAdd==''){
					this.$message.error("请输入街道地址")
				}else if(this.form.contacts==undefined){
					this.$message.error("请输入联系人")
				}else if(this.form.contacts==''){
					this.$message.error("请输入联系人")
				}else if(this.form.phone==undefined){
					this.$message.error("请输入电话号码")
				}else if(this.form.phone==''){
					this.$message.error("请输入电话号码")
				}else if(!(/^1[3456789]\d{9}$/.test(this.form.phone))){
					this.$message.error("请输入正确的电话号码")
				}else{
					let data={
						userid: this.form.id,
						id: this.form.itemid,
						province:this.form.province,
						city:this.form.city,
						region:this.form.area,
						address:this.form.streetAdd,
						contactuser:this.form.contacts,
						contacttel:this.form.phone,
						isdefault:this.form.type
					}
					updateAdd(data).then(res=>{
						if(res.code=="200"){
							this.type=false
							this.$message.success(
								'修改成功',
							)
							this.getUser()
						}else{
							this.$message.error(
								'修改失败',
							)
						}
					})
				}
			},
			
			//-----------------修改------------------------
			updataClick:function(item){
				this.addType = 1;
				this.form.province = item.province; //省
				this.form.city = item.city; //市
				this.form.area = item.region; //取
				this.form.streetAdd = item.address; //街道地址
				this.form.contacts = item.contactuser; //联系人
				this.form.phone = item.contacttel; //联系电话
				this.form.itemid = item.id;
				if(item.isdefault==1){
					this.form.type = 1; //是否默认
				}else{
					this.form.type = undefined; //是否默认
				}
				this.type=true;
			},
			
			//-----------------删除对应数据-----------------------
			deleteValue:function(){
				let data={
					id:this.radioId,
					uid:this.userInfo.uid,
					isdelete:1,
				}
				updateAdd(data).then(res=>{
					this.$message.success(
						'删除成功',
					)
					this.getMyAddList();
				})
			}
		}
	}
</script>

<style lang="less" scoped>

</style>